import React from "react"

import BackButton from "./../components/BackButton"

import api from "./../lib/api"
import Loader from "../components/Loader"

import {FaPlay, FaPause, FaBackward, FaForward} from "react-icons/fa"
import ReactSlider from "react-slider"

export default class Controls extends React.Component {
    constructor(props) {
        super()

        this.state = {
            loading: (props.options.state === "loading"),
            status: {
                state: "stopped",
                current: {}
            },
            seek: false,
            timeLoading: false,
            lastSeek: false
        }
    }

    async control(type, options) {
        const status = await api.control(type, options)
        this.setState({
            status
        })
    }

    async seek(time) {
        this.setState({
            seek: false,
            lastSeek: time,
            timeLoading: true
        })

        const status = await api.control("seek", {seek: time})
        this.setState({
            status
        })
    }
 
    componentDidMount() {
        this.interval = setInterval(async () => {
            const res = await api.getControlStatus()

            this.setState({
                loading: (res.state === "loading"),
                status: res,
                timeLoading: (this.state.timeLoading && Math.abs(this.lastSeek - res.time) > 5)
            })
                
            // } else if (res.state == "error") {
            //     this.props.page.back()
            // }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    stop() {
        this.control("stop")
        // TODO: go back to movie
        this.props.page.goto("home")
    }

    renderPosition() {
        if (this.state.timeLoading) {
            return (
                <div className="position">
                    <div class="loader"></div>
                </div>
            )
        } else {
            const {time, length} = this.state.status
            const timeValue = this.state.seek !== false ? this.state.seek : time

            const timeString = new Date(timeValue * 1000).toISOString().substr(11, 8)
            const lengthString = new Date(length * 1000).toISOString().substr(11, 8)

            return (
                <div className="position">
                    <div className="slider time">
                        {timeString}
                    </div>
                    <ReactSlider 
                        className="horizontal-slider"
                        trackClassName="slider-track"
                        thumbClassName="slider-thumb"
                        value={time}
                        min={0}
                        max={length}
                        onBeforeChange={
                            (val) => this.setState({seek: val})
                        }
                        onAfterChange = {
                            (val) => this.seek(val)
                        }
                        onChange={(val) => this.setState({seek: val})}
                    />
                    <div className="slider duration">
                        {lengthString}
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.state.status.state === "stopped" || this.state.status.length === -1) {
             return <Loader />
        }

        const host = api.getEndpoint()
        const {state, current} = this.state.status
        const {Title, imdbID} = current


        const posterUrl = `url(${host}/poster/${imdbID}.jpg)`

        return (
            <div className="player-controls-container">
                <div className="back-container">
                    <BackButton page={this.props.page} />
                </div>

                <div className="poster" style={{"backgroundImage": posterUrl}}>
                </div>

                <div className="controls">
                    <h4>{Title}</h4>

                    {this.renderPosition()}
                    

                    <div className="buttons btn-group btn-block">
                        <button className="btn btn-secondary" onClick={() => this.control("seek", {seek: "-5m"})}><FaBackward /></button>
                        <button className="btn btn-primary" onClick={() => this.control(state === "playing" ? "pause" : "play")}>
                            { state === "playing" ?
                                <FaPause /> :
                                <FaPlay />
                            }   
                        </button>
                        <button className="btn btn-secondary"><FaForward onClick={() => this.control("seek", {seek: "+5m"}) }/></button>
                    </div>

                    <button className="btn btn-block btn-danger" onClick={() => this.stop()}>Stop Playing</button>
                </div>
            </div>
        )
    }
}