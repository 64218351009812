let endpoint = "/api/"

const setEndpoint = (host) => {
    endpoint = `${host}/api/`
}

const get = async(url) => {
    const res = await fetch(`${endpoint}${url}`)
    const json = await res.json()
    if (json.error) {
        throw new Error(json.error)
    } else {
        return json
    }
}

const post = async(url, body={}) => {
    const res = await fetch(`${endpoint}${url}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json"
        }
    })
    const json = await res.json()
    if (json.error) {
        throw new Error(json.error)
    } else {
        return json
    }
}

const search = async (query) => {
    const res = await get(`search?q=${query}`)
    return res
}

const setTitle = async (id) => {
    const res = await post(`title/${id}`)
    return res
}

const getTitle = async(id) => {
    const title = await get(`title/${id}`)
    return title
}

const getTorrents = async (type, title, season, episode, amount) => {
    const res = await get(`torrents?title=${title}&type=${type}&season=${season}&episode=${episode}&amount=${amount}`)
    return res
}

const addTorrent = async(torrent) => {
    const res = await post(`torrent`, {
        torrent
    })
    return res
}

const play = async (torrent, file, position, omdb, episode) => {
    const res = await post(`play`, {
        torrent,
        file,
        position,
        omdb,
        episode
    })
    return res
}

const getControlStatus = async () => {
    const res = await get(`control/status`)
    return res
}

const control = async (cmd, options={}) => {
    const body = {
        cmd,
        ...options
    }

    const res = await post("control", body)
    return res
}

const getSeason = async(title, season) => {
    const res = await get(`title/${title}/${season}`)
    return res
}

const getEpisode = async(title, season, episode) => {
    const res = await get(`title/${title}/${season}/${episode}`)
    return res
}

const getNextEpisode = async(episode) => {
    console.log("Next episode for: ", episode)
    const season = await getSeason(episode.seriesID, episode.Season)
    let nextEpisode = null
    for (let i=0; i<season.Episodes.length; i++) {
        if (season.Episodes[i].Episode === episode.Episode) {
            // found current episode
            nextEpisode = i < season.Episodes.length ? season.Episodes[i+1] : null
            break
        }
    }

    if (nextEpisode) {
        nextEpisode.Season = episode.Season
        console.log("Got next episode", nextEpisode)
        return await getTitle(nextEpisode.imdbID)
    }

    // try next series
    if (parseInt(episode.Season) < parseInt(season.totalSeasons)) {
        const nextSeries = await getSeason(episode.seriesID, parseInt(episode.Season) + 1)
        console.log("Got next episode", nextSeries.Episodes[0])
        return await getTitle(nextSeries.Episodes[0].imdbID)
    }
}

const getRecent = async() => {
    return await get("recent")
}

const getEndpoint = () => {
    return endpoint
}

const api = {
    setEndpoint,
    get,
    search,
    getTitle,
    setTitle,
    getTorrents,
    addTorrent,
    play,
    getControlStatus,
    control,
    getSeason,
    getEpisode,
    getEndpoint,
    getRecent,
    getNextEpisode
}

export default api