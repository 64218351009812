import React from "react"

export default class SearchResult extends React.Component {
    constructor() {
        super()

        this.onClick = this.onClick.bind(this)
    }

    onClick(e) {
        e.preventDefault()
        const {result, setTitle} = this.props
        setTitle(result.imdbID)
    }

    render() {
        const {Title, Year} = this.props.result

        return (
            <div className="row">
                <a className="col-md-12 search-result" href="/" onClick={this.onClick}>
                    <h4>{Title} ({Year})</h4>
                </a>
            </div>
        )
    }
}