import React from "react"

import BackButton from "./../components/BackButton"
import Button from "react-bootstrap/Button"
import Loader from "./../components/Loader"
import ReactPlaceholder from "react-placeholder"

import api from "./../lib/api"

export default class Series extends React.Component {
    constructor() {
        super()
        
        this.state = {
            season: 1,
            episodes: [],
            seasonInfo: {},
            seasonLoading: true,
            selectedEpisode: null
        }

        this.play = this.play.bind(this)
        this.setSeason = this.setSeason.bind(this)
    }

    componentDidMount() {
        this.updateEpisodes()
    }


    async updateEpisodes() {
        const titleId = this.props.options.title.imdbID
        const season = this.state.season

        this.setState({seasonLoading: true})
        const seasonInfo = await api.getSeason(titleId, season)

        this.setState({seasonInfo, episodes: seasonInfo.Episodes, seasonLoading: false})

        for (let i=0; i<seasonInfo.Episodes.length; i++) {
            const episode = seasonInfo.Episodes[i]
            const res = await api.getTitle(episode.imdbID)

            if (this.state.season === season) {
                this.setState((s) => {
                    const episodes = Object.assign([], s.episodes)
                    episodes[i] = res
                    return {
                        episodes
                    }
                })
            } else {
                break
            }
        }
    }

    play(e, playFromStart) {
        e.preventDefault()
        const title = this.props.options.title
        const episode = this.state.selectedEpisode

        this.props.page.goto("torrents", {
            type: "tv",
            omdb: title,
            episode,
            playFromStart
        })
    }

    renderPlayButton(title) {
        const stored = title.stored
        const runtime = parseInt(title.Runtime.split(" min")[0]) * 60
        if (stored && stored.position) {
            return (
                <>
                    <div className="progress-container">
                        <div className="bar" style={{"width": Math.round(stored.position * 100 / runtime) + "%"}} />
                    </div>

                    <Button variant="primary" size="" onClick={(e) => this.play(e, false)}>Resume</Button>&nbsp;
                    <Button variant="secondary" onClick={(e) => this.play(e, true)}>Play from start</Button>
                </>
            )
        } else {
            return (
                <>
                    <Button variant="primary"  onClick={(e) => this.play(e, true)}>Play</Button>
                </>
            )
        }
    }

    selectEpisode(episode) {
        api.setTitle(episode.imdbID)
        this.setState({
            selectedEpisode: episode
        })
    }

    renderEpisode(episode) {
        const runtime = parseInt((episode.Runtime || "").split(" min")[0]) * 60

        return (
            <button className="reset-button d-flex flex-row mb-2 search-result episode" href="#" onClick={(e) => { e.preventDefault(); this.selectEpisode(episode)}}>
                <div className="d-none col-2 d-lg-flex">
                    <ReactPlaceholder showLoadingAnimation={true} type="rect" color="#E0E0E0" ready={episode.Poster !== undefined} style={{width: "100%", height:"96px"}}>
                        <img src={episode.Poster} alt="" style={{width: "100%"}}/>
                    </ReactPlaceholder>

                    {episode.Runtime && episode.stored && episode.stored.position ? 
                        <div className="progress-container">
                            <div className="bar" style={{"width": Math.round(episode.stored.position * 100 / runtime) + "%"}} />
                        </div> : null
                    }   
                </div>
                
                <div className="">
                    <h4>{episode.Season}.{episode.Episode}: {episode.Title}</h4>
                    <ReactPlaceholder showLoadingAnimation={true} type="text" ready={episode.Plot !== undefined} color="#E0E0E0" rows={3}>
                        <p>{episode.Plot}</p>
                    </ReactPlaceholder>

                    <div className="mb-2">
                        {this.state.selectedEpisode && this.state.selectedEpisode.imdbID === episode.imdbID && episode.Runtime ? this.renderPlayButton(episode): null}
                    </div>
                    
                </div>
            </button>
        )
    }

    renderSeason() {
        const {episodes, seasonLoading} = this.state

        if (seasonLoading) {
            return <Loader />
        } else {
            return episodes.map((e) => this.renderEpisode(e))
        }
    }

    async setSeason(e) {
        await this.setState({
            season: e.target.value
        })

        this.updateEpisodes()
    }

    render() {
        const omdb = this.props.options.title
        const seasons = []
        for (let i=1; i<=parseInt(omdb.totalSeasons); i++) {
            seasons.push((
                <option value={i}>Season {i}</option>
            ))
        }
        return (
            <div className="container mt-5 movie">
                <BackButton page={this.props.page} />

                <div className="info">
                    <h2>{omdb.Title} ({omdb.Year})</h2>
                    <p>{omdb.Runtime}</p>
                    <p className="plot">{omdb.Plot}</p>
                </div>

                <div className="episode-select mt-5 mb-4">
                    <div className="form-group">
                        <select className="form-control" value={this.state.season} onChange={this.setSeason}>
                            {seasons}
                        </select>
                    </div>
                </div>

                {this.renderSeason()}
            </div>
        )
    }
}