import React from "react"
import BackButton from "../components/BackButton"

export default class TorrentFiles extends React.Component {
    constructor() {
        super()

        this.state = {
            loading: false
        }

        this.renderFile = this.renderFile.bind(this)
    }

    renderFile(file) {
        return (
            <button key={file.desc} className="button-reset row search-result" href="#" onClick={(e) => {e.preventDefault(); this.props.options.callback(file.index)}}>
                <div className="col-md-12 ">
                    <p className="name">{file.path}</p>
                    <p className="tags">
                        <span className="badge badge-primary">Size: {file.length}B</span>
                    </p>
                </div>
            </button>
        )
    }

    render() {
        const {omdb, episode, torrent} = this.props.options
        let files = []
        for (let i=0; i<torrent.files.length; i++) {
            torrent.files[i].index = i
            files.push(torrent.files[i])
        }
        files.sort((a, b) => {
            const aPath = a.path.toUpperCase()
            const bPath = b.path.toUpperCase()

            if (aPath < bPath) {
                return -1
            }

            if (aPath > bPath) {
                return 1
            }

            return 0
        })
        files = files.map(this.renderFile)

        return (
            <div className="container mt-5">
                <BackButton page={this.props.page} />
                <div className="files mt-4">
                    {episode ? 
                        <h3 className="mb-4">Select a file for {omdb.Title} Season {episode.Season} Episode {episode.Episode}</h3> : 
                        <h3 className="md-4">Select a file for {omdb.Title}</h3>
                    }
                    {files}
                </div>
            </div>
        )
    }
}