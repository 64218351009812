import React from "react"

import BackButton from "./../components/BackButton"
import Button from "react-bootstrap/Button"

export default class Movie extends React.Component {
    constructor() {
        super()
        this.play = this.play.bind(this)
    }

    play(e, playFromStart) {
        e.preventDefault()
        const title = this.props.options.title
        this.props.page.goto("torrents", {
            type: "movies",
            omdb: title,
            playFromStart
        })
    }

    renderPlayButton() {
        const title = this.props.options.title
        const stored = title.stored
        const runtime = parseInt(title.Runtime.split(" min")[0]) * 60
        if (stored && stored.position) {
            return (
                <>
                    <div className="progress-container">
                        <div className="bar" style={{"width": Math.round(stored.position * 100 / runtime) + "%"}} />
                    </div>

                    <Button variant="primary" size="" onClick={(e) => this.play(e, false)}>Resume</Button>&nbsp;
                    <Button variant="secondary" onClick={(e) => this.play(e, true)}>Play from start</Button>
                </>
            )
        } else {
            return (
                <>
                    <Button variant="primary" size="lg" block onClick={(e) => this.play(e, true)}>Play</Button>
                </>
            )
        }
    }

    render() {
        const omdb = this.props.options.title
        return (
            <div className="container mt-5 movie">
                <BackButton page={this.props.page} />

                <div className="info">
                    <h2>{omdb.Title} ({omdb.Year})</h2>
                    <p>{omdb.Runtime}</p>
                    <p className="plot">{omdb.Plot}</p>
                </div>

                <div className="controls mt-5">
                       {this.renderPlayButton()}
       
                </div>
            </div>
        )
    }
}