import React from "react"
import Loader from "../components/Loader"
import Logo from "../components/Logo"


export default class Devices extends React.Component {
    constructor(props) {
        super()
        this.state = {
            devices: [],
            loading: true
        }
        this.renderDevice = this.renderDevice.bind(this)
    }

    async componentDidMount() {
        const key = this.props.userKey

        const res = await fetch("https://api.uniflix.hatt.co/user?userKey=" + key)
        const {user} = await res.json()

        this.setState({
            devices: user.devices,
            loading: false
        })
    }

    renderDevice(device) {
        const onlineClass = (device.last_seen - Date.now() < 1000 * 60 * 5) ? "online" : "offline"

        return (
            <button className={`reset-button row search-result device ${onlineClass}`} onClick={() => this.props.onSelect(device.id)}>
                <div className="col-md-12">
                    <h3>{device.title}</h3>
                </div>
            </button>
        )
    }

    render() {
        const devices = this.state.devices.map(this.renderDevice)
        
        if (this.state.loading) {
            return (
                <Loader />
            )
        }

        return (
            <div className="container mt-5">
                <Logo />
                {devices}
            </div>
            
        )
    }
}