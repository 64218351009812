import React from "react"

import SearchIcon from "./../assets/Search-Find-52.png"

export default class SearchBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
          query: ""
        }
        this.search = props.search

        this.onChange = this.onChange.bind(this)
        this.doSearch = this.doSearch.bind(this)
      }
    
    onChange(e) {
        this.setState({
          query: e.target.value
        })
    }

    async doSearch(e) {
        e.preventDefault()
        console.log("searching", this.state.query)
        await this.search(this.state.query)
    }

    render() {
        const query = this.state.query

        return (
            <div className="search-container mt-4 mb-3">
              <form action="#" onSubmit={this.doSearch}>
                <div className="input-group input-group-lg">
                    <input className="form-control input-lg" value={query} onChange={this.onChange} placeholder="Search..." />
                    <button className="btn btn-outline-secondary search-button">
                        <img src={SearchIcon} alt="Search"></img>
                    </button>
                </div>
              </form>
            </div>
        )
    }
}