import React from "react"
import Loader from "../components/Loader"
import Logo from "../components/Logo"

import SearchBar from "./../components/SearchBar"
import api from "./../lib/api"
export default class Home extends React.Component {
    constructor() {
        super()
        this.state = {
            currentPlaying: null,
            recent: [],
            continueWatchLoading: true
        }

        this.renderTitle = this.renderTitle.bind(this)
    }

    componentDidMount() {
        this.update()
    }

    componentDidUpdate() {
        this.update()
    }

    async update() {
        const res = await api.getControlStatus()

        if (res.state !== "stopped" && res.current) {
            this.setState({
                currentPlaying: res.current
            })
        }

        const recent = await api.getRecent()
        this.setState({
            recent,
            continueWatchLoading: false
        })

    }

    async continueWatching(title) {
        this.setState({
            loading: true
        })

        const omdb = await api.getTitle(title.imdbID)
        if (title.lastEpisodeID) {
            let episode = await api.getTitle(title.lastEpisodeID)
            const lengthInSeconds = parseInt(episode.Runtime.split(" ")[0]) * 60

            if (omdb.stored.position && omdb.stored.position > (lengthInSeconds * 0.95)) {
                episode = await api.getNextEpisode(episode)        
            }

            this.props.page.goto("torrents", {
                type: "tv",
                omdb,
                episode,
                playFromStart: false
            })
        } else {
            this.props.page.goto("torrents", {
                type: "movies",
                omdb,
                playFromStart: false
            })
        }
        this.setState({
            loading: false
        })

    }

    renderTitle(title) {
        const host = api.getEndpoint()
        return (
            <button className="reset-button title" onClick={() => this.continueWatching(title)}>
                <img src={`${host}/poster/${title.imdbID}`} alt={title.title}></img>
                <div className="progress-container">
                    <div className="bar" style={{"width": Math.round(title.position * 100 / title.length) + "%"}} />
                </div>
            </button>
            
        )
    }
    render() {
        const currentPlaying = this.state.currentPlaying
        const recentTitles = this.state.recent.map(this.renderTitle)

        if (this.state.loading) {
            return <Loader />
        }

        return (
        <>
            <div className="container mt-5">
                <Logo />
                <SearchBar search={this.props.search} />

                {currentPlaying ? 
                    <button className="current-playing btn btn-block btn-primary" onClick={() => this.props.page.goto("controls", {})}>Now playing: {currentPlaying.Title}</button> : null
                }

                <div className="row mt-4">
                    <div className="col-md-12">
                        <h5>Continue Watching...</h5>
                    </div>
                </div>

                
            </div>

            <div className="container recent">
            { this.state.continueWatchLoading ? 
                <Loader /> : recentTitles }
            </div>
        </>      
        )
    }
}