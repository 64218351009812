import React from "react"
import LogoImage from "./../assets/logo.png"


export default class Logo extends React.Component {
    render() {
        return (
            <div className="logo">
                <img src={LogoImage} alt="" />
                <h1>Uniflix</h1>
            </div>
        )
    }
}