import React from "react"

export default class Loader extends React.Component {
    render() {
        const show = this.props.show || true
        const text = this.props.text
        
        if (show) {
            return (
                <div className="loader-container">
                    {text ? 
                        <h2>{text}</h2> : null
                    }
                    <div className="loader">

                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}