import React from "react"
import Loader from "../components/Loader"
import BackButton from "../components/BackButton"
import api from "./../lib/api"

export default class Torrents extends React.Component {
    constructor() {
        super()

        this.state = {
            loading: true,
            loadingText: "Loading...",
            error: null,
            torrents: [],
            amount: 15,
            torrentType: "show"
        }
    }

    async getTorrents() {
        const {omdb, type, episode, back} = this.props.options
        if (omdb.stored && omdb.stored.torrents && !back) {
            let selectedFile = null
            let selectedTorrent = null
            for (const torrent of omdb.stored.torrents) {
                const file = this.findFile(torrent)
                console.log(torrent, file)
                if (file != null) {
                    selectedFile = file
                    selectedTorrent = torrent 
                }
                if (torrent && type === "tv" && torrent.season === episode.Season && torrent.episode === episode.Episode) {
                    selectedFile = torrent.file
                    selectedTorrent = torrent
                    break
                }
            }

            if (selectedFile !== null && selectedTorrent) {
                this.addTorrent({torrent: selectedTorrent.magnet}, selectedFile)
                return
            }
        }

        if (this.props.options.type === "movies") {
            this.getMovieTorrents()
        } else {
            this.getTVTorrents()
        }
    }

    async getMovieTorrents() {
        const {omdb, type} = this.props.options

        this.setState({
            loading: true,
            loadingText: "Searching torrents..."
        })

        const torrents = await api.getTorrents(type, omdb.Title + " " + omdb.Year, null, null, this.state.amount)
        console.log("torrents", torrents)
        this.setState({
            loading: false,
            torrents
        })
    }

    
    async getTVTorrents() {
        const {omdb, episode} = this.props.options

        this.setState({
            loading: true,
            loadingText: "Searching torrents..."
        })

        let torrents = []
        const {torrentType, amount} = this.state

        const paddedSeason = ("0" + episode.Season).substr(-2)
        const paddedEpisode = ("0" + episode.Episode).substr(-2)

        if (torrentType === "show") {
            torrents = await api.getTorrents("tv", `${omdb.Title} complete`, episode.Season, episode.Episode, amount)
        } else if (torrentType === "season") {
            torrents = await api.getTorrents("tv", `${omdb.Title} ${episode.Season} complete`, episode.Season, episode.Episode, amount)
        } else if (torrentType === "episode") {
            torrents = await api.getTorrents("tv", `${omdb.Title} ${paddedSeason}x${paddedEpisode}`, episode.Season, episode.Episode, amount)
        }

        this.setState({
            loading: false,
            torrents
        })
    }

    async componentDidMount(props) {
        this.getTorrents()
    }

    async loadMore(e) {
        e.preventDefault()
        await this.setState((s) => {
            s.amount = s.amount + 15
            return s
        })

        this.getTorrents()
    }

    renderLoader() {
        return (
            <Loader text={this.state.loadingText} />
        )
    }

    findFile(torrent) {
        const {type, episode} = this.props.options
        if (type === "movies") {
            // Finding the right file for a movie torrent is easy, just get the largest file
            const files = torrent.files
            let largestIndex = -1
            let largestLength = 0
            for (let i=0; i<files.length; i++) {
                if (files[i].length > largestLength) {
                    largestIndex = i
                    largestLength = files[i].length
                }
            }

            if (largestIndex > -1) {
                return largestIndex
            } else {
                return null
            }
        } else {
             // try and find right episode
            const foundEpisodes = []
            const paddedSeason = ("0" + episode.Season).substr(-2)
            const paddedEpisode = ("0" + episode.Episode).substr(-2)

            for (let i=0; i<torrent.files.length; i++) {
                const file = torrent.files[i]
                const name = file.name.toLowerCase()
                console.log(name, `s${paddedSeason}e${paddedEpisode}`)
                if (name.indexOf(`s${paddedSeason}e${paddedEpisode}`) > -1 || name.indexOf(`${paddedSeason}x${paddedEpisode}`) > -1) {
                    file.index = i
                    foundEpisodes.push(file)
                }
            }

            if (foundEpisodes.length > 0) {
                foundEpisodes.sort((a, b) => {
                    return b.length - a.length
                })

                return foundEpisodes[0].index
            } else {
                return null
            }
        }
       
    }

    async addTorrent(data, file=null, selectFile=false) {
        try {
            this.setState({
                loading: true,
                loadingText: "Fetching torrent..."
            })
            const {torrent} = await api.addTorrent(data.torrent)
            
            if (file) {
                return this.playTorrent(torrent, file)
            }

            const fileIndex = this.findFile(torrent)
            console.log(torrent, fileIndex)
            if (!selectFile && fileIndex !== null) {
                await this.playTorrent(torrent, fileIndex)
            } else {
                const {omdb, episode} = this.props.options
                this.props.page.goto("files", {
                    omdb,
                    episode,
                    torrent,
                    callback: (file) => {
                        console.log("Selected", file)
                        this.playTorrent(torrent, file)
                    }
                })
            }
        } catch(e) {
            console.log(e.stack)
            this.setState({loading: false, error: e.message})
        }
    }

    async playTorrent(torrent, file) {
        const {omdb, episode, playFromStart} = this.props.options
        const position = playFromStart ? null : omdb.stored.position
        
        try {
            if (!torrent.id) {
                throw new Error("Invalid torrent - please choose another")
            }

            this.setState({loading: true})
            await api.play(torrent.id, file, position, omdb, episode)
            this.props.page.goto("controls", {
                omdb,
                state: "loading"
            })
        } catch(e) {
            this.setState({loading: false, error: e.message})
        }
        
    }

    renderTorrent(torrent) {
        const {speed, quality, title, language, size} = torrent

        const seedClass = "seed " + speed.toLowerCase().replace(new RegExp(" ", "g"), "")
        let qualityClass = ""

        if (quality === "1080p") {
            qualityClass = "veryfast"
        } else if (quality === "720p") {
            qualityClass = "fast"
        } else {
            qualityClass = "slow"
        }

        return (
            <div className="row">
                <button className="reset-button col-md-12 search-result" href="#" onClick={() => { this.addTorrent(torrent) }}>
                    <p className="name">{title}</p>
                    <p className="tags">
                        <span className={`badge ${seedClass}`}>{speed}</span>
                        { quality ? 
                            <span className={`badge ${qualityClass}`}>{quality}</span> : null
                        }
                        { language ? 
                            <span className={`badge slow`}>{language}</span> : null
                        }
                        <span className="badge badge-secondary">{size}</span>
                    </p>
                </button>
            </div>  
        )
    }

    async onTypeChange(e) {
        await this.setState({
            torrentType: e.target.value,
            loading: true
        })
        this.getTorrents()
    }

    renderTorrentType() {
        return (
            <div className="mt-4 form-group">
                <select className="form-control" onChange={(e) => this.onTypeChange(e)} value={this.state.torrentType}>
                    <option value="show">Whole show torrents</option>
                    <option value="season">Season torrents</option>
                    <option value="episode">Episode torrents</option>
                </select>
            </div>
        )
    }

    render() {
        const {loading, torrents, error} = this.state
        const type = this.props.options.type

        if (loading) {
            return this.renderLoader()
        }

        const torrentElms = torrents.map(t => this.renderTorrent(t))
        return (
            <div className="container mt-5">
                <BackButton page={this.props.page} />
                {type === "tv" ? this.renderTorrentType() : null}
                {error ? 
                    <div className="alert alert-danger">{error}</div> : null
                }
                <div className="torrents mt-4">
                    
                    {torrentElms.length ? 
                        torrentElms :
                        <h2>No torrents found :(</h2>
                    }

                    {torrentElms.length ? 
                        <button className="btn btn-block btn-secondary btn-lg mt-2 mb-2" onClick={(e) => { this.loadMore(e) }}>Load More...</button> : null 
                    }
                </div>
            </div>
        )
    }
}