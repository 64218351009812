import React from "react"
import api from "./lib/api"

import "./styles/Global.scss"
import "./styles/Remote.scss"
import "react-placeholder/lib/reactPlaceholder.css"

import Home from "./pages/Home"
import Search from "./pages/Search"
import Movie from "./pages/Movie"
import Torrents from "./pages/Torrents"
import Controls from "./pages/Controls"
import Series from "./pages/Series"
import TorrentFiles from "./pages/TorrentFiles"
import Devices from "./pages/Devices"


export default class Remote extends React.Component {
    constructor() {
        super()
        this.state = {
            page: "devices",
			history: [],
            results: [],
            options: {},
            optionsHistory: [],
            server: null,
            key: window.location.search.split("?key=")[1]
        }

        this.goto = this.goto.bind(this)
		this.back = this.back.bind(this)
		
		this.search = this.search.bind(this)

        this.page = {
            goto: this.goto,
            back: this.back
        }

        this.selectDevice = this.selectDevice.bind(this)
    }


    goto(page, options) {
        this.setState((s) => {
            const newHistory = [...s.history]
            const optionsHistory = [...s.optionsHistory]

			if (s.page !== page) {
                newHistory.push(s.page)
                optionsHistory.push(Object.assign({}, s.options))
            }
            
            console.log(newHistory, optionsHistory)
			
            return {
                page,
                history: newHistory,
                options,
                optionsHistory
            }
        })
    }

    back() {
        const history = Object.assign([], this.state.history)
        const optionsHistory = Object.assign([], this.state.optionsHistory)
        const page = history.pop()
        const options = optionsHistory.pop()
		
		console.log("back", page, options)
        options.back = true

        this.setState({
            page,
            history,
            options,
            optionsHistory
        })
	}
	
	async search(query) {
		const res = await api.search(query)
		const results = res.Search ? res.Search : []
		this.goto("results", {results})
	}

    async selectDevice(id) {
        const port = window.location.search.indexOf("&dev") > -1 ? 8080 : 443
        api.setEndpoint(`https://${id}.dev.uniflix.hatt.co:${port}`)
        this.goto("home")

    //     const res = await api.getControlStatus()

    //     if (res.state !== "stopped" && res.current) {
    //         this.goto("controls", {})
    //     }
    }

    renderPage(page) {
        switch (page) {
            case "devices":
                return <Devices userKey={this.state.key} onSelect={this.selectDevice}/>
            case "home":
                return <Home search={this.search} page={this.page}/>
            case "results":
                return <Search search={this.search} options={this.state.options} page={this.page}/>
            case "movie":
                return <Movie options={this.state.options} page={this.page} />
            case "series":
                return <Series options={this.state.options} page={this.page} />
            case "torrents":
                return <Torrents options={this.state.options} page={this.page} />
            case "files":
                return <TorrentFiles options={this.state.options} page={this.page} />
            case "controls":
                return <Controls options={this.state.options} page={this.page} />
            default:
                return
        }
    }

    render() {
		const page = this.renderPage(this.state.page)
		
		return (
			<div className="App">
				<div className="background">
			
				</div>
	
				{page}
				
			</div>
		)
    }
}
