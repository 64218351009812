import React from "react"

import Back from "./../assets/Back-54.png"

export default class BackButton extends React.Component {
    constructor() {
        super()
    
        this.click = this.click.bind(this)
    }

    click(e) {
        e.preventDefault()
        this.props.page.back()
    }

    render() {
        return (
            <button className="reset-button back-button" onClick={this.click}>
                <img alt="back" src={Back} />
            </button> 
        )
    }
}