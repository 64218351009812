import React from "react"

import SearchResult from "./../components/SearchResult"
import SearchBar from "./../components/SearchBar"
import BackButton from "./../components/BackButton"

import api from "./../lib/api"

export default class SearchResults extends React.Component {
    constructor() {
        super()

        this.setTitle = this.setTitle.bind(this)
    }

    async setTitle(id) {
        const page = this.props.page
        const title = await api.setTitle(id)  
        page.goto(title.Type === "movie" ? "movie" : "series", {
            title: Object.assign({}, title)
        })  
    }

    render() {
        let results = this.props.options.results
        results = results ? results.map(res => <SearchResult result={res} key={res.imdbID} setTitle={this.setTitle}/>) : null
        const noResults = results.length === 0
    
        return (
            <div className="container mt-5">
                <BackButton page={this.props.page} />
                <SearchBar search={this.props.search} />
                {noResults ? 
                
                    <div className="none-found text-center">
                    No results found
                    </div> :
                    results
                }
            </div>
            
        )
    }
}